import React from 'react';
import '../components/css/FeedNotices.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const FeedNotices = () => {
  return (
    <div>
      {/* Primeiro bloco */}
      <div className="ContainerNews card px-3 pt-3" style={{ maxWidth: "32rem" }}>
        {/* News block */}
        <div>
          {/* Featured image */}
          <div className="bg-image hover-overlay shadow-1-strong ripple rounded-5 mb-4" data-mdb-ripple-color="light">
            <img src="https://mdbcdn.b-cdn.net/img/new/fluid/city/113.webp" className="img-fluid" />
            <a href="#!">
              <div className="mask" style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}></div>
            </a>
          </div>

          {/* Article data */}
          <div className="row mb-3">
            <div className="col-6">
              <a href="" className="text-info">
                <i className="fas fa-plane"></i>
                Travels
              </a>
            </div>

            <div className="col-6 text-end">
              <u> 15.07.2020</u>
            </div>
          </div>

          {/* Article title and description */}
          <a href="" className="text-dark">
            <h5>This is title of the news 11111111</h5>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit, iste aliquid. Sed
              id nihil magni, sint vero provident esse numquam perferendis ducimus dicta
              adipisci iusto nam temporibus modi animi laboriosam?
            </p>
          </a>
        </div>
        {/* News block */}
      </div>
      {/* Fim do primeiro bloco */}

      {/* Segundo bloco */}
      <div className="ContainerNews1 card px-3 pt-3" style={{ maxWidth: "32rem" }}>
        {/* News block */}
        <div>
          {/* Featured image */}
          <div className="bg-image hover-overlay shadow-1-strong ripple rounded-5 mb-4" data-mdb-ripple-color="light">
            <img src="https://mdbcdn.b-cdn.net/img/new/fluid/city/113.webp" className="img-fluid" />
            <a href="#!">
              <div className="mask" style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}></div>
            </a>
          </div>

          {/* Article data */}
          <div className="row mb-3">
            <div className="col-6">
              <a href="" className="text-info">
                <i className="fas fa-plane"></i>
                Travels
              </a>
            </div>

            <div className="col-6 text-end">
              <u> 15.07.2020</u>
            </div>
          </div>

          {/* Article title and description */}
          <a href="" className="text-dark">
            <h5>This is title of the news 2222222222</h5>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit, iste aliquid. Sed
              id nihil magni, sint vero provident esse numquam perferendis ducimus dicta
              adipisci iusto nam temporibus modi animi laboriosam?
            </p>
          </a>
        </div>
        {/* News block */}
      </div>
      {/* Fim do segundo bloco */}
    </div>
  );
};

export default FeedNotices;
