import React from 'react';
import Penguin from '../components/imgs/error404.gif';
import '../components/css/Error404.css';

const Error404 = () => {
  return (
    <div className="gif-container">
      <h1>404</h1>
      <img src={Penguin} alt="GIF" className="gif-image" />
    </div>
  );
};

export default Error404;