import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SideBar from './components/SideBar';
import FeedNotices from './pages/FeedNotices';
import Home from './pages/Home';
import Error404 from './components/Error404';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <SideBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/feednotices" element={<FeedNotices />} />
          <Route path="/error404" element={<Error404 />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
