import React from 'react';
import '../components/css/SideBar.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import LogoA from './imgs/Allizarin-logo-01-100px.png';
import IconHome from '../components/imgs/icon-home.svg';
import IconNews from '../components/imgs/icon-news.svg';
import IconJava from '../components/imgs/icon-java.png';
import IconAws from '../components/imgs/icon-aws.svg';
import IconLinux from '../components/imgs/icon-linux.png';
import IconDocker from '../components/imgs/icon-docker.svg';
import IconK from '../components/imgs/icon-kubernetes.svg';

const SideBar = () => {
  return (
    <div className="SideBar container-fluid">
      <div className="row flex-nowrap">
        <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark">
          <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
            <Link to="/" className="LogoA">
              <img src={LogoA} alt="Menu Icon" className="menu-icon" />
              <hr />
            </Link>
            <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
              <li className="nav-item">
                <Link to="/" className="nav-link align-middle px-0 text-white">
                  <img src={IconHome} className="icon-home" /> <span className="fontA ms-1 d-none d-sm-inline">Home</span>
                </Link>
                <Link to="/feednotices" className="nav-link align-middle px-0 text-white">
                  <img src={IconNews} className="icon-notices" /> <span className="fontA ms-1 d-none d-sm-inline">Notices</span>
                </Link>
                <Link to="#" className="nav-link align-middle px-0 text-white">
                  <img src={IconJava} className="icon-java" /> <span className="fontA ms-1 d-none d-sm-inline">Java</span>
                </Link>
                <Link to="#" className="nav-link align-middle px-0 text-white">
                  <img src={IconAws} className="icon-aws" /> <span className="fontA ms-1 d-none d-sm-inline">AWS</span>
                </Link>
                <Link to="#" className="nav-link align-middle px-0 text-white">
                  <img src={IconLinux} className="icon-linux" /> <span className="fontA ms-1 d-none d-sm-inline">Linux</span>
                </Link>
                <Link to="/" className="nav-link align-middle px-0 text-white">
                  <img src={IconDocker} className="icon-docker" /> <span className="fontA ms-1 d-none d-sm-inline">Docker</span>
                </Link>
                <Link to="#" className="nav-link align-middle px-0 text-white">
                  <img src={IconK} className="icon-kubernetes" /> <span className="fontA ms-1 d-none d-sm-inline">Kubernetes</span>
                </Link>
              </li>
            </ul>
            <hr />
            <Link to="/error404" className="d-flex align-items-center text-white text-decoration-none">
              <img src="https://m.media-amazon.com/images/I/51OC9gtzm4L._AC_UF350,350_QL80_.jpg" alt="hugenerd" width="30" height="30" className="rounded-circle" />
              <span className="d-none d-sm-inline mx-1">Profile</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
