import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import SideBar from '../components/SideBar';

const Home = () => {
  return (
    <div>
        <SideBar />
    </div>
  );
};

export default Home;
